import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../../styles/inputs.css";
import { parse } from 'best-effort-json-parser'
import { format } from "date-fns";
import CustomPrompt from "./prompt";

function GenerateTripButton(props) {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [inputsValid, setInputsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([])
  const [doneFilter, setDoneFilter] = useState(false)

  const inputValidation = () => {
    return props.destination.length && props.fromValue && props.toValue;
  };

  useEffect(() => {
    setInputsValid(inputValidation());
  }, [props.toValue, props.fromValue, props.destination.address]);

  const handleMouseMove = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = ((e.clientX - rect.left) * 100) / button.clientWidth;
    const y = ((e.clientY - rect.top) * 100) / button.clientHeight;
    setMouseX(x);
    setMouseY(y);
  };

  const buttonStyle = {
    "--mouse-x": `${mouseX}`,
    "--mouse-y": `${mouseY}`,
  };

  let { toValue, fromValue, destination, isRestaurants, tripType, dietaryPreferences, anyThingElse, interest, arrivalDate, departureDate , localFlightDetail , time } = props;

  let selectedInterest = interest?.filter((int) => int?.isSelected)


  let prompt = CustomPrompt(props)


    const handleSubmit = async (e) => {
    if (inputsValid) {
      e.preventDefault();
      setLoading(true);
      props.setApiResponse("");
      props.setStreamDone(false);
      props.setFlightDetail(props.localFlightDetail)

      try {
        const result = await fetch(process.env.REACT_APP_TRIP_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: prompt,   }),
        });

        props.setGenerateClicked(true);

        const reader = result.body.pipeThrough(new TextDecoderStream()).getReader();
        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            props.setStreamDone(true);
            break;
          }

          props.setApiResponse((prevApiResponse) => prevApiResponse + value);
        }
      } catch (e) {
        console.error(e);
        props.setApiResponse({ error: "Something is going wrong. Please try again." });
      }
      setLoading(false);
    }
  };

  const getFilter = async () => {
    try {
      props.setFilterLoading(true)
      props.setInterest(null);
      setFilter(null);
      setDoneFilter(false);

      const result = await fetch(process.env.REACT_APP_TRIP_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // prompt: `generate a list of 10 interest tags based on  ${destination.map(des => des.address).join(', ')} .make sure the interest was related to the location, make array of object wit key label and the array key was the "interests", The array should have exactly 10 items`,
          prompt: `generate a list of 10 interest tags  based on  ${destination.map(des => des.address).join(', ')} .make sure the interest was related to the location, make array of object wit key label and the array key was the "interests", The array should have exactly 10 items `,
          system: 'You are a expert that will be asked to suggest interest tags.You format the result in json: parent \"interests\",  where each interest includes \"label\",'
          // system: "Generate a list of 10 interest tags related to the provided addresses. Return the results as an array of objects with keys 'label' and 'value'. Ensure the array has exactly 10 items."
         }),
      });

      const reader = result.body.pipeThrough(new TextDecoderStream()).getReader();
      let apiResponse = '';

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          setDoneFilter(true);
          break;
        }

        apiResponse += value;
      }

      setFilter(apiResponse);
    } catch (e) {
      console.error(e);
      // props.setApiResponse({ error: "Something is going wrong. Please try again." });
    } finally {
      props.setFilterLoading(false)
    }
  };


  useEffect(() => {
    if (doneFilter) {
      props.setInterest(parse(filter)?.interests)

    }
  }, [doneFilter, filter])

  useEffect(() => {
    if (props.destination.length) {
      getFilter()
    } else {
      props.setInterest([])
    }
  }, [props.destination])

  return (
    <div className="generate-container">
      <button
        className={`generate-button ${!inputsValid ? "disabled" : ""} `}
        onMouseMove={handleMouseMove}
        style={buttonStyle}
        disabled={!inputsValid || loading}
        // onClick={(e) => props.step === 3 ? handleSubmit(e) : nextStep()}
        onClick={(e) => handleSubmit(e)}
      >
        {loading ? (
          <>
            Generating <FontAwesomeIcon style={{ marginBottom: "-2px" }} icon={faSpinner} spin />
          </>
        ) : !inputsValid ? (
          "Complete your trip preferences"
        ) : (
          "Generate trip now - It's free!"
        )}
        {/* {loading ? (
          <>
            Generating <FontAwesomeIcon style={{ marginBottom: "-2px" }} icon={faSpinner} spin />
          </>
        ) : props.step === 3 ? "Generate trip now - It's free!" : 'Next'} */}
      </button>
      {!inputsValid ? "" : <FontAwesomeIcon className="generate-icon" icon={faPlane} />}
    </div>
  );
}

export default GenerateTripButton;

let tripTypeCondtion = {
  family: "for a family",
  romantic: "for a couple",
  cultural: "for cultural",
  adventure: "for exciting adventure",
  party: "for party",
};
